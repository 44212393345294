// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---docs-architecture-architecture-mdx": () => import("./../../../../docs/Architecture/Architecture.mdx" /* webpackChunkName: "component---docs-architecture-architecture-mdx" */),
  "component---docs-introduction-introduction-mdx": () => import("./../../../../docs/Introduction/Introduction.mdx" /* webpackChunkName: "component---docs-introduction-introduction-mdx" */),
  "component---docs-qualtrics-qualtrics-mdx": () => import("./../../../../docs/Qualtrics/qualtrics.mdx" /* webpackChunkName: "component---docs-qualtrics-qualtrics-mdx" */),
  "component---docs-survey-lifecycle-survey-lifecycle-mdx": () => import("./../../../../docs/SurveyLifecycle/SurveyLifecycle.mdx" /* webpackChunkName: "component---docs-survey-lifecycle-survey-lifecycle-mdx" */),
  "component---docs-voting-methods-voting-methods-mdx": () => import("./../../../../docs/VotingMethods/VotingMethods.mdx" /* webpackChunkName: "component---docs-voting-methods-voting-methods-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

